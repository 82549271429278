<template>
  <div
    class="d-flex flex-column h-100"
    style="font-family: var(--default-font)"
  >
    <div v-if="label" class="tw-mb-1">
      <lazy-library-text
        :text="label"
        tag="span"
        type="input-label"
      ></lazy-library-text>
      <span v-if="isRequired" style="color: #ff0000; font-size: 14px">*</span>
    </div>
    <span
      class="n-input h-100 w-100 tw-flex tw-items-center tw-bg-white tw-px-4"
      style="height: 60px !important"
    >
      <span class="tw-text-2xl tw-font-normal">£</span>
      <input
        v-model="priceModel"
        maxlength="19"
        class="w-100 h-100 tw-pl-3 nc-text tw-font-medium"
        type="text"
        :disabled="disabled"
        @keydown="checkDigit"
        @keyup="$emit('keyup')"
      />
    </span>
  </div>
</template>

<script>
export default {
  name: 'CurrencyInput',
  props: {
    price: { default: 0, type: Number },
    disabled: {
      type: Boolean,
      default: false,
    },

    label: {
      required: false,
      type: String,
      default: '',
    },

    isRequired: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:price', 'keyup'],

  computed: {
    priceModel: {
      get() {
        return Number(this.price) !== 0
          ? Number(this.price)
              .toFixed(0)
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
          : '';
      },
      set(value) {
        this.$emit(
          'update:price',
          value ? parseInt(value.replace(/[^0-9]/g, ''), 10) : 0,
        );
      },
    },
  },
  methods: {
    checkDigit(event) {
      const evt = event || window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>

<style scoped>
.n-input input {
  border: 0;
}

.n-input {
  border: 1px solid var(--default-border-color);
}

.n-input input:focus {
  outline: none;
}
</style>
