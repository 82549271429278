<template>
  <div class="tw-flex tw-flex-row tw-items-center tw-gap-x-4">
    <div class="n-chart-container" aria-label="Deposit percentage">
      <div class="n-chart__empty_part"></div>
      <span class="n-chart__percentage"
        ><lazy-library-text
          :text="`${displayPercentage}%`"
          class="tw-font-black"
        ></lazy-library-text></span
      ><svg
        class="n-chart__value_part"
        viewBox="0 0 320 320"
        aria-hidden="true"
      >
        <circle
          class="n-chart__circle"
          r="140"
          cx="160"
          cy="160"
          :style="`stroke-dashoffset: ${value}; stroke-dasharray: ${total}, ${total}`"
        ></circle>
      </svg>
    </div>
    <lazy-library-text :text="description"></lazy-library-text>
  </div>
</template>

<script lang="ts">
import { calculatePercentageValue } from '~/helpers';

export default defineNuxtComponent({
  name: 'DoughnutChart',
  props: {
    percentage: {
      required: false,
      type: Number,
      default: 10,
    },
  },

  data() {
    return {
      total: 879.646,
    };
  },

  computed: {
    description() {
      if (this.percentage >= 25) {
        return 'Lenders typically charge lower interest rates on loans with higher deposits';
      } else if (this.percentage >= 10) {
        return 'Lenders may expect more than a 10% deposit';
      } else if (this.percentage === 0) {
        return 'Few lenders offer 100% mortgages, these will typically have higher interest rates';
      }
      return 'Low deposits will typically have higher interest rates';
    },

    displayPercentage() {
      return Math.ceil(this.percentage);
    },

    value() {
      const totalValuePercentage = calculatePercentageValue(
        this.percentage,
        this.total,
      );

      return this.total - totalValuePercentage;
    },
  },
});
</script>

<style scoped>
.n-chart-container {
  color: #e9e9eb;
  width: 72px;
  height: 72px;
  position: relative;
  flex-shrink: 0;
}

.n-chart-container .n-chart__percentage {
  position: absolute;
  display: inline-block;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #222222;
  line-height: 72px;
}

.n-chart-container .n-chart__value_part {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  fill: transparent;
  stroke: var(--nc-doughnut-chart-value);
  stroke-width: 30px;
  -ms-transform: rotate(-90deg);
  -ms-transform-origin: 50% 50%;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}

.n-chart-container .n-chart__value_part .n-chart__circle {
  transition: 0.35s stroke-dashoffset;
}

.n-chart-container .n-chart__empty_part {
  position: absolute;
  height: 68px;
  width: 68px;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  border-radius: 50%;
  border: 4px solid var(--default-border-color);
}
</style>
